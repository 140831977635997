import React from 'react';
import { Link } from 'gatsby';
import Clipboard from 'react-clipboard.js';
import 'moment/locale/it';
import Moment from 'react-moment';
import HTMLReactParser, { HTMLReactParserOptions } from 'html-react-parser';

import Author from './author';
import Layout from './layout';
import { PostToc, PostTocList, PostTocListItem } from './post-toc';
import ScrollIntoView from './scroll-into-view';
import SVG from './svg';
import CategoryWidget from './category-widget';
import TagsWidget from './tags-widget';
import FabButton from './fab-button';
import ShortcodeParserService, {
  ShortcodeStructure,
} from '../services/shortcode-parser-service';
import { WpPost } from '../models';
import { FormWrapper } from './leads-components/form-wrapper';
import HTMLParser from './html-parser';

type BlogPostProps = {
  previous: WpPost;
  data: {
    page: {
      date: string;
      uri: string;
      title: string;
      content: string;
      author: any;
      categories: any;
      tags: any;
      seo?: any;
    };
  };
  next: WpPost;
};

type BlogPostState = {
  parsedContent: any;
  menuIsOpen: boolean;
};

type PostTocType = {
  [key: string]: {
    parent: PostTocListItem;
    children: PostTocType;
  };
};

export default class BlogPost extends React.Component<
  BlogPostProps,
  BlogPostState
> {
  private paragraphsMap: { [key: string]: React.Component };

  private paragraphsRef: { [key: string]: React.RefObject<PostTocListItem> };

  constructor(props) {
    super(props);
    this.state = {
      menuIsOpen: false,
      parsedContent: undefined,
    };

    this.paragraphsMap = {};
    this.paragraphsRef = {};
  }

  private prevNode;

  onIntoView = (event: { isVisible: boolean; node: string }) => {
    if (this.paragraphsRef[event.node]) {
      if (event.isVisible) {
        this.paragraphsRef[event.node].current?.setVisibility(event.isVisible);
        this.paragraphsRef[this.prevNode]?.current?.setVisibility(false);
        this.prevNode = event.node;
      }
    }
  };

  componentDidMount() {
    // TODO Ottimizzare questo codice
    const options: HTMLReactParserOptions = {
      replace: (node) => {
        if (node.name === 'h2') {
          const ref = React.createRef<PostTocListItem>();
          this.paragraphsRef = {
            ...this.paragraphsRef,
            [node.children[0].data]: ref,
          };

          // TODO Questa mappa potrebbe non servire

          /*
           * Praticamnete l'idea è creare una mappa "nome": Componente per permettere la creazione
           * del TOC (Table of Contents). Tale TOC avrà un comportamento diverso tra desktop e mobile, motivo per cui
           * è necessario traccare l'evento click che su dispositivi mobile, chiuderà il menu così da permettere
           * all'utente di visualuzzare il paragrafo che ha cliccato, vedere appunto la prop href che corrisponde all'id della sezione
           */

          this.paragraphsMap = {
            ...this.paragraphsMap,
            [node.children[0].data]: (
              <PostTocListItem
                className=""
                key={node.attribs?.id}
                ref={ref}
                href={node.attribs?.id}
                text={node.children[0].data}
                click={() => {
                  this.setState({
                    menuIsOpen: false,
                  });
                }}
              />
            ),
          };

          return (
            <ScrollIntoView
              node={node.children[0].data}
              onIntoView={this.onIntoView}
            >
              <h2
                id={node.attribs?.id}
                className="relative inline-flex items-center space-x-2 text-red-500 cursor-pointer"
              >
                <Clipboard
                  data-clipboard-text={`${window.location.origin}${this.props.data.page?.uri}#${node.attribs?.id}`}
                >
                  <SVG
                    className="stroke-current"
                    path="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                  />
                </Clipboard>
                <span>{node.children[0].data}</span>
              </h2>
            </ScrollIntoView>
          );
        }

        if (node.name === 'h3') {
          const ref = React.createRef<PostTocListItem>();
          this.paragraphsRef = {
            ...this.paragraphsRef,
            [node.children[0].data]: ref,
          };

          this.paragraphsMap = {
            ...this.paragraphsMap,
            [node.children[0].data]: (
              <PostTocListItem
                className="pl-3 text-left"
                ref={ref}
                key={node.attribs?.id}
                href={node.attribs?.id}
                text={node.children[0].data}
                click={() => {
                  this.setState({
                    menuIsOpen: false,
                  });
                }}
              />
            ),
          };

          return (
            <ScrollIntoView
              node={node.children[0].data}
              onIntoView={this.onIntoView}
            >
              <h3
                id={node.attribs?.id}
                className="relative pr-5 inline-flex items-center space-x-2 cursor-pointer"
              >
                <Clipboard
                  data-clipboard-text={`${window.location.origin}${this.props.data.page?.uri}#${node.attribs?.id}`}
                >
                  <SVG
                    className="stroke-current"
                    path="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"
                  />
                </Clipboard>
                <span>{node.children[0].data}</span>
              </h3>
            </ScrollIntoView>
          );
        }

        if (node.data) {
          const shortcodes = ShortcodeParserService.extractWpShortcode(
            node.data,
          );

          if (shortcodes) {
            const shortcodeParsed: ShortcodeStructure = ShortcodeParserService.parse(
              shortcodes[0],
            );

            /* return React.createElement(leadsFormsMap[shortcodeParsed.name], {
                ...shortcodeParsed.attributes,
              }); */

            return <FormWrapper {...shortcodeParsed.attributes} />;
          }
        }
      },
    };

    this.setState({
      parsedContent: HTMLReactParser(this.props.data.page?.content, options),
    });
  }

  render = () => (
    <Layout seo={this.props.data.page?.seo}>
      <FabButton
        className="md:hidden"
        click={(e) => {
          this.setState({
            menuIsOpen: !this.state.menuIsOpen,
          });
        }}
      />
      {this.state.menuIsOpen ? (
        <div
          role="dialog"
          className="top-0 left-0 m-2 mb-10 p-5 h-screen flex flex-col justify-between space-y-8 fixed shadow rounded bg-white z-40 overflow-y-auto transition-opacity"
        >
          <Author author={this.props.data.page?.author.node} />
          <CategoryWidget
            categories={this.props.data.page?.categories.nodes}
          />
          <PostToc classList="" title="Contents">
            <PostTocList>{Object.values(this.paragraphsMap)}</PostTocList>
          </PostToc>
          <TagsWidget tags={this.props.data.page?.tags.nodes} />
        </div>
      ) : (
        ''
      )}

      <article className="md:pl-5 w-full mx-auto space-y-8">
        <div className="relative w-full flex justify-center space-x-40">
          <div className="md:pl-10 py-32 flex flex-col overflow-y-auto">
            <Moment locale="it" format="DD MMMM YYYY">
              {this.props.data.page?.date}
            </Moment>
            <h1>{this.props.data.page?.title}</h1>
            <article className="w-full mt-10 right-0 prose lg:prose-lg">
              {!this.state.parsedContent ? (
                <HTMLParser>{this.props.data.page.content}</HTMLParser>
              ) : (
                this.state.parsedContent
              )}
            </article>
            <hr />
            <div className="mt-16 flex flex-col md:flex-row md:items-center md:justify-between md:space-x-8 space-y-1 md:space-y-0">
              <span className="flex-none font-medium text-sm">
                Continua la lettura
              </span>
              <div className="w- flex flex-col space-y-4 md:space-y-12">
                <Link className="flex-1" to={this.props.previous?.uri}>
                  <article className="flex items-center flex-1 space-x-2 cursor-pointer text-base font-normal text-gray-800 no-underline">
                    <span>{this.props.previous?.title}</span>
                    <SVG
                        className="w-7 h-3 text-orange-700 stroke-current"
                        path="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                  </article>
                </Link>
                <Link className="flex-1" to={this.props.next?.uri}>
                  <article className="flex items-center flex-1 space-x-2 cursor-pointer text-base font-normal no-underline">
                    <span className="text-right">
                        {this.props.next?.title}
                      </span>
                    <SVG
                        className="w-7 h-3 text-orange-700 stroke-current"
                        path="M17 8l4 4m0 0l-4 4m4-4H3"
                      />
                  </article>
                </Link>
              </div>
            </div>
          </div>
          <div className="hidden xl:text-sm xl:block xl:w-1/4 xl:px-6">
            <div className="flex flex-col justify-between md:space-y-10 overflow-y-auto sticky max-h-screen pt-12 pb-4 mt-56 top-0">
              <Author author={this.props.data.page?.author.node} />
              <CategoryWidget
                categories={this.props.data.page?.categories.nodes}
              />
              <PostToc classList="" title="Contents">
                <PostTocList>{Object.values(this.paragraphsMap)}</PostTocList>
              </PostToc>
              <TagsWidget
                tags={this.props.data.page?.tags.nodes}
              />
            </div>
          </div>
        </div>
      </article>
    </Layout>
  );
}
