import React from "react";

type FabButtonProps = {
    className?: string;
    click?: any;
}

const FabButton: React.FC<FabButtonProps> = (props: FabButtonProps): JSX.Element => (
  <button className={`${props.className} bottom-0 right-0 p-5 mr-5 mb-5  fixed rounded-full bg-gray-800 outline-none shadow-none z-50`} onClick={props.click}>
    <svg
      className="w-6 h-6 text-white"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 6h16M4 10h16M4 14h16M4 18h16"
      />
    </svg>
  </button>
);

export default FabButton;