import React from "react";

type PostTocListProps = { className?: string; children?: any };

const PostTocList: React.FC<PostTocListProps> = (
  props: PostTocListProps
): JSX.Element => (
  <ul className={`space-y-1 list-none ${props.className}`}>{props.children}</ul>
);

export default PostTocList;
