import React from "react";

type PostTocListItemProps = { text?: string; href?; className?: string, click?: () => any };
type PostTocListItemState = { isVisible: boolean };

export default class PostTocListItem extends React.Component<
  PostTocListItemProps,
  PostTocListItemState
> {
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }

  setVisibility = (isVisible) => {
    this.setState({
      isVisible,
    });
  };

  render() {
    return (
      <li className={`${this.props.className}`} onClick={this.props.click}>
        <a
          className={`text-sm text-gray-700 cursor-pointer hover:text-orange-600 hover:font-bold ${
            this.state.isVisible ? "font-bold text-orange-600" : "font-normal"
          }`}
          href={`#${this.props.href}`}
        >
          {this.props.text}
        </a>
        {this.props.children}
      </li>
    );
  }
}
