import { Link } from "gatsby";
import React from "react";
import { BadgeTrasparent } from "./badges";

type TagsWidgetProps = {
  tags: any;
};

const TagsWidget: React.FC<TagsWidgetProps> = (
  props: TagsWidgetProps
): JSX.Element => (
  <div className="flex flex-wrap">
    {props.tags.map((tag) => (
      <Link className="mr-2 mb-2" to={`${tag.uri}`}>
        <BadgeTrasparent
          className="text-xs text-gray-500"
          key={tag.name}
          text={`#${tag.name}`}
        ></BadgeTrasparent>
      </Link>
    ))}
  </div>
);

export default TagsWidget;
