import React from "react";
import { graphql } from "gatsby";
import BlogPost from "../components/blog-post";

export default ({ data, pageContext: { previous, next } }) => (
  <BlogPost previous={previous} data={data} next={next}></BlogPost>
);

export const query = graphql`
  query post($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    page: wpPost(id: { eq: $id }) {
      id
      uri
      title
      content
      date
      tags {
        nodes {
          id
          name
          description
          uri
          slug
        }
      }
      categories {
        nodes {
          id
          name
          uri
          slug
        }
      }
      author {
        node {
          name
          avatar {
            url
          }
        }
      }
      ... WpSEOPost
    }
  }
`;
