import React from "react";

type PostTocProps = {
  classList?: string;
  title?: string;
};

const PostToc: React.FC<PostTocProps> = (props): JSX.Element => (
  <div className={`w-56 flex flex-col space-y-3 ${props.classList}`}>
    <span className="inline-flex items-center space-x-1 text-gray-500 text-xs font-semibold uppercase">
      <svg
        className="w-3 h-3"
        fill="none"
        stroke="currentColor"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
        ></path>
      </svg>
      <span>{props.title}</span>
    </span>
    {props.children}
  </div>
);

export default PostToc;