import React from "react";
import Iubenda from "react-iubenda-policy";
import * as anvironment from "../../config/environment";
import { Input } from "./input";

type PrivacyPolicyCheckboxProps = {
  className?: string;
}

const PrivacyPolicyCheckbox: React.FC<PrivacyPolicyCheckboxProps> = ({ className }): JSX.Element => (
  <Input name="privacy-policy" type="checkbox" className={className} required={true}>
    Accetto la{" "}
    <Iubenda id={anvironment.IubendaPrivacyId} type="privacy">
      privacy policy
    </Iubenda>
  </Input>
);

export default PrivacyPolicyCheckbox;
