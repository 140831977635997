import React, { InputHTMLAttributes } from "react";
import { Field } from "formik";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  classname?: string;
  label?: string;
}

export const Input: React.FC<InputProps> = (props: InputProps): JSX.Element => (
  <div className="flex items-center space-x-1">
    <Field
      type={props.type}
      className={`rounded ${
        props.type === "checkbox" ? `flex-none` : `flex-1`
      } ${
        !props.classname
          ? `border bg-white ${props.className}`
          : props.classname
      }`}
      id={props.id}
      name={props.name}
      defaultValue={props.defaultValue}
      placeholder={props.placeholder}
      required={props.required}
      // onBlur={props.onBlur}
      // onChange={props.onChange}
    ></Field>
    {props.type === "checkbox" ? (
      <label>{props.label || props.children}</label>
    ) : (
      ""
    )}
  </div>
);
