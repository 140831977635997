import { Link } from "gatsby";
import React from "react";
import { BadgeSecondary } from "./badges";

// TODO Stessa props di category-list, vedere come gestire
type CategoryListProps = {
  categories: any;
};

const CategoryWidget: React.FC<CategoryListProps> = (
  props: CategoryListProps
): JSX.Element => (
  <div className="flex flex-wrap">
    {props.categories.map((category) => (
      <Link className="mr-2 mb-2" to={`${category.uri}`}>
        <BadgeSecondary category={category.name}></BadgeSecondary>
      </Link>
    ))}
  </div>
);

export default CategoryWidget;
