import React from "react";


// TODO In questo caso ha senso tipizzare author? 
type AuthorProps = {
  author: any
}

const Author: React.FC<AuthorProps> = (props: AuthorProps): JSX.Element => {
  const {
    name,
    avatar: { url: avatarUrl },
  } = props.author;

  return (
    <div className="flex items-center space-x-2">
      <img className="w-10 h-10 rounded-full" src={avatarUrl} alt={name} />
      <dl className="text-sm">
        <dt className="sr-only">Name</dt>
        <dd className="font-medium">{name}</dd>
        <dt className="sr-only">Role</dt>
        <dd className="text-xs text-gray-600">Head of Marketing</dd>
      </dl>
    </div>
  );
};

export default Author;