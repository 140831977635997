import React from "react";
import VizSensor from "react-visibility-sensor";

type ScrollIntoViewProps = { node?: any; onIntoView?: any };
type ScrollIntoViewState = { isVisible: boolean };

export default class ScrollIntoView extends React.Component<
  ScrollIntoViewProps,
  ScrollIntoViewState
> {
  constructor(props) {
    super(props);
    this.state = { isVisible: false };
  }

  onIntoView = (isVisible) => {
    this.setState({
      isVisible,
    });

    this.props.onIntoView({ node: this.props.node, isVisible });
  };

  render() {
    return (
      <VizSensor partialVisibility={true} onChange={this.onIntoView}>
        {this.props.children}
      </VizSensor>
    );
  }
}
